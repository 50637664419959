import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from './utils/axios'

Vue.use(ElementUI);
Vue.prototype.$axios = axios
Vue.prototype.$tokenKey = '_fertsfdserter'
Vue.prototype.$downloadUrl = 'https://www.lanzouh.com/b00rmxpldi'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
